<template id="long-tab-bar">
  <div class="long-tab-bar-container">
    <div class="button-container">
      <ion-button v-if="swiperNeeded" fill="clear" @click="(visibleOptionIndex <= 0) ? undefined : goToPrevious()" :disabled="visibleOptionIndex <= 0" class="navigation-button back-button">
        <ion-icon :icon="chevronBack" slot="icon-only"></ion-icon>
      </ion-button>
    </div>

    <div class="long-tab-bar-slides-container"> <!-- TODO Make the arrow progress one and show indicator how many there are!-->
      <ion-slides ref="slidesRef" class="long-tab-bar-slides" v-if="selectableOptions" :options="slideOpts" :key="selectableOptions">
        <ion-slide v-for="(option, optionIndex) in selectableOptions" :key="optionIndex" @click="selectOption(option.key)">
          <div :class="['slide-container', ...slideContainerStatusClasses(option.key)]">
            <template v-if="option.icon != null">
              <ion-icon class="option-icon" :icon="option.icon" :color="(option.iconColor != null) ? option.iconColor : undefined"></ion-icon>
            </template>
            <ion-label class="slide-label">{{ option.display || emptyDisplayFallback || i18n.$t('default_interaction.no_text') }}</ion-label>
          </div>
        </ion-slide>
      </ion-slides>
    </div>

    <div class="button-container">
      <ion-button v-if="swiperNeeded" fill="clear" @click="((visibleOptionIndex + 1) >= optionCount) ? undefined : goToNext()" :disabled="(visibleOptionIndex + 1) >= optionCount" class="navigation-button forward-button">
        <ion-icon :icon="chevronForward" slot="icon-only"></ion-icon>
      </ion-button>
    </div>

    <div class="button-container">
      <ion-button v-if="swiperNeeded" id="all-options-button" fill="outline" @click="showAllOptions">
        <ion-icon :icon="list" slot="icon-only"></ion-icon>
      </ion-button>
    </div>
  </div>
</template>

<script>
import { IonSlides, IonSlide, IonLabel, IonButton, IonIcon, actionSheetController } from '@ionic/vue';
import { defineComponent, computed, ref, watch, onMounted } from 'vue';

import { chevronBack, chevronForward, chevronDown, list } from 'ionicons/icons';

import _ from 'lodash';

import { useI18n } from "@/utils/i18n";

export default defineComponent({
  name: 'LongTabBar',
  components: { IonSlides, IonSlide, IonLabel, IonButton, IonIcon },
  props: {
    'title': String, //Title will be shown in selection popup as header
    'selectableOptions': {
      type: Array, //Array of objects with display and key set
      default: () => []
    },
    'selected': [String, Number],
    'dangerIndicatorForKeys': Array,
    'primaryIndicatorForKeys': Array,
    'successIndicatorForKeys': Array,
    'resetViewDelay': {
      type: Number, //After this amount of milliseconds, the view will go back to the selected option. 0 means, it never goes back
      default: 5000
    },
    'emptyDisplayFallback': String
  },
  emits: ['update:selected'],
  setup(props, { emit }) {
    const i18n = useI18n();

    const slidesRef = ref(null);

    //Initially set to the first one, will change once the selection changes
    const visibleOptionIndex = ref(0);

    //True if there is not enough space for all elements and the swiper is required! Initially true, if enough space with buttons, also enough space without them!
    const swiperNeeded = ref(true);

    const selectedProp = computed(() => {
      return props.selected;
    });

    //Saved internally in case the selected prop is never given
    const selectedOptionKey = ref(selectedProp.value);

    //Update selection from the outside (only if it differs to not trigger all watchers unnecessarily)
    watch(selectedProp, (newSelectedKey) => {
      if (selectedOptionKey.value != newSelectedKey) selectedOptionKey.value = newSelectedKey;
    });

    //Infer the index from the selected key and the selectableOptions
    const selectedOptionIndex = computed(() => {
      if (selectedOptionKey.value == null || props.selectableOptions == null) return null;
      let index = _.findIndex(props.selectableOptions, (option) => option.key === selectedOptionKey.value);

      if (index >= 0) return index;
      return null;
    });

    const optionCount = computed(() => {
      if (props.selectableOptions != null) {
        return props.selectableOptions.length;
      }
      return 0;
    });

    const slideOpts = {
      updateOnWindowResize: true,
      resizeObserver: true,
      centeredSlides: false,
      slidesPerView: 'auto',
      watchOverflow: true,
      zoom: false,
      keyboard: {
        enabled: true
      },
      initialSlide: 0, //Start on the first slide everything else will be handled by the selection logic
      init: false //Swiper is initalized manually on mount
    };

    const goToPrevious = async function(){
      try {
        let swiper = await slidesRef.value.$el.getSwiper();
        if (swiper) {
          swiper.slidePrev();
        }
      } catch {
        return;
      }
    }

    const goToNext = async function(){
      try {
        let swiper = await slidesRef.value.$el.getSwiper();
        if (swiper) {
          swiper.slideNext();
        }
      } catch {
        return;
      }
    }

    const goToIndex = async function(index, swiperInstance){
      try {
        let swiper = (swiperInstance != null) ? swiperInstance : (await slidesRef.value.$el.getSwiper());
        if (swiper) {
          swiper.slideTo(index);
        }
      } catch {
        return;
      }
    }

    const selectOption = function(optionKey) {
      selectedOptionKey.value = optionKey;
      emit('update:selected', optionKey);
    }

    //Used to move view back to selection - Gets cleared on selection or on view change - any interaction
    const resetViewTimer = ref(null);

    /* When the selection changes move view immediately to center this selection. Also triggers on load when it is not null on mount. */
    const moveToNewSelectedOption = function(newSelectedOptionIndex) {
      //Reset timer as we already moved and don't want to move back!
      if (resetViewTimer.value != null) clearTimeout(resetViewTimer.value);
      //Only go to selection if it is not null and different from the already visible index
      if (newSelectedOptionIndex != null && newSelectedOptionIndex != visibleOptionIndex.value) goToIndex(newSelectedOptionIndex); 
    }

    //Call on change and on mount, when it is not null
    watch(selectedOptionIndex, (newSelectedOptionIndex) => moveToNewSelectedOption(newSelectedOptionIndex));
    onMounted(() => {
      if (selectedOptionIndex.value != null) moveToNewSelectedOption(selectedOptionIndex.value);
    })

    //Reset scroll position after timeout if enabled. Triggered after every scroll index change
    const startNewTimerResetViewToSelected = function() {
      //If enabled, reset the view to the selected index after timeout
      if (props.resetViewDelay != null && props.resetViewDelay > 0) {
        if (resetViewTimer.value != null) clearTimeout(resetViewTimer.value);
        resetViewTimer.value = setTimeout(() => {
          if (selectedOptionIndex.value != null && selectedOptionIndex.value != visibleOptionIndex.value) goToIndex(selectedOptionIndex.value, this);
        }, props.resetViewDelay);
      }
    }

    const initSwiper = function(){
      if (slidesRef.value != null) {
        swiperNeeded.value = true; //Reset parameter to show buttons!

        try {
          slidesRef.value.$el.getSwiper().then((swiper) => {
            if (swiper) {
              //Set edge cases on beginning and end to catch uneven scrolling with varying slide widths and always have correct beginning and end behaviour!
              swiper.on('reachBeginning', () => {
                visibleOptionIndex.value = 0;
                startNewTimerResetViewToSelected();
              });
              swiper.on('reachEnd', () => {
                visibleOptionIndex.value = optionCount.value;
                startNewTimerResetViewToSelected();
              });
              //Inverse handler from the above two handlers, to leave edge cases again.
              //Thus catches edge case where the second to last element is the active index and we switch to it from the end. activeIndex has to be second to last again, so use activeIndex!
              //Needs to check if it is the edge, in case there are only two elements, because then end and fromEdge get both triggered!
              swiper.on('fromEdge', function(){ 
                if (!this.isBeginning && !this.isEnd) visibleOptionIndex.value = this.activeIndex;
                startNewTimerResetViewToSelected();
              });

              swiper.on('activeIndexChange', function () {
                //Only set if not beginning or end, those are handled by separate listeners
                if (!this.isBeginning && !this.isEnd) visibleOptionIndex.value = this.activeIndex;
                startNewTimerResetViewToSelected();
              });

              //Interaction locked, no swiper necessary
              swiper.on('lock', () => swiperNeeded.value = false);
              //Interaction unlocked we need a swiper
              swiper.on('unlock', function() {
                swiperNeeded.value = true;
                //Also reset the active index to correctly enable scrolling!
                visibleOptionIndex.value = this.activeIndex;
              });
              //Move to selected index initially after init
              swiper.on('init', function(){
                if (selectedOptionIndex.value != null) goToIndex(selectedOptionIndex.value, this);
              });

              //On resize go immediately to the selected one again!
              swiper.on('resize', function(){ 
                //Moved already, cancel timers
                if (resetViewTimer.value != null) clearTimeout(resetViewTimer.value);
                if (selectedOptionIndex.value != null && selectedOptionIndex.value != visibleOptionIndex.value) goToIndex(selectedOptionIndex.value, this);
              });

              //Workaround to fix errors on resize, because we do not use navigation, but it tries to call navigation update, when locking the swiper
              //From: https://github.com/nolimits4web/swiper/issues/3620
              swiper.navigation = swiper.navigation ? swiper.navigation : {
                update: () => {  }
              };

              return swiper.init();
            }
          });
        } catch {
          return;
        }
      }
    }

    const showAllOptions = async function(){
      return actionSheetController
        .create({
          header: props.title || undefined,
          buttons: [
            ..._.map(props.selectableOptions, (option) => {
              return {
                text: option.display || props.emptyDisplayFallback || i18n.$t('default_interaction.no_text'),
                cssClass: (option.key == selectedOptionKey.value) ? 'all-options-selection-selected' : undefined,
                handler: () => selectOption(option.key)
              };
            }),
            {
              text: i18n.$t('default_interaction.cancel'),
              icon: chevronDown,
              role: 'cancel'
            },
          ],
        })
        .then(a => a.present());
    }

    const slideContainerStatusClasses = computed(() => {  
      const classArrayOptions = {
        'danger': props.dangerIndicatorForKeys,
        'primary': props.primaryIndicatorForKeys,
        'success': props.successIndicatorForKeys
      }

      return function(optionKey) {
        let classes = [];
        if (selectedOptionKey.value === optionKey) classes.push('selected');
        
        for (let [statusClass, arrayOfKeysForStatus] of Object.entries(classArrayOptions)) {
          if (Array.isArray(arrayOfKeysForStatus) && arrayOfKeysForStatus.includes(optionKey)) classes.push(statusClass);
        }

        return classes;
      }
    });

    //Initialize swiper each time the instance changes (happens when the options change, because they are set as key)
    watch(slidesRef, () => {
      initSwiper();
    });

    return { i18n, slidesRef, swiperNeeded, slideOpts, visibleOptionIndex, selectedOptionKey, optionCount, goToPrevious, goToNext, selectOption, showAllOptions, slideContainerStatusClasses, chevronBack, chevronForward, list };
  }
});
</script>

<style>
.all-options-selection-selected {
  font-weight: bold;
  color: var(--ion-color-primary-text)!important;
}
</style>

<style scoped>
.long-tab-bar-container {
  display: flex;
  flex-flow: row;
  padding: 5px 0px;
  align-items: stretch;
  --additional-width-offset: var(--width-offset, 0px);
}

.button-container {
  display: flex;
  align-items: center;
}

/* Always listen for click events, to not click through to neighbouring elements accidentally */
.navigation-button {
  pointer-events: auto!important;
  height: 100%;
}

.navigation-button.button-disabled {
  opacity: 0.3;
}

ion-button {
  width: 38px!important;
  flex-shrink: 0;
  height: 38px;
  margin: 0px;
  --padding-start: 0px;
  --padding-end: 0px;
}

#all-options-button {
  margin-left: 10px;
}

ion-slides {
  width: 100%;
}

ion-slide {
  cursor: pointer;
  width: max-content;
}

.long-tab-bar-slides-container {
  display: flex;
  overflow: hidden;
  position: relative;
  width: 100%;
  padding: 5px 0px;
}

.long-tab-bar-slides-container::after {
  pointer-events: none;
  position: absolute;
  content: "";
  top: -20px;
  bottom: -20px;
  left: 0px;
  right: 0px;
  z-index: 1000;
  box-shadow: inset 0px 0px 10px 10px var(--background, transparent);
}

.slide-container {
  padding: 10px min(4vw, 30px);
  max-width: calc(60vw - var(--additional-width-offset, 0px)); /* Ensures that it stays in bounds with all the buttons present! */
  font-size: clamp(0.75em, 2vw, 0.9em);
  display: flex;
  flex-direction: row;
  align-items: center;
}

.slide-container::before{
  content: "";
  position: absolute;
  display: block;
  width: 70%;
  height: 2px;
  bottom: 0;
  left: 15%;
  background-color: var(--ion-color-primary);
  transform: scaleX(0);
  transition: transform 0.3s ease;
}

.slide-container.selected::before{
  transform: scaleX(1);
}

.slide-container.selected {
  color: var(--ion-color-dark);
}

.slide-container.primary {
  color: var(--ion-color-primary-text);
}

.slide-container.success {
  color: var(--ion-color-success);
}

.slide-container.danger {
  color: var(--ion-color-danger);
}

.option-icon {
  margin-inline-end: 5px;
}

/* Limit label to 3 lines to prevent it taking unwanted space! */
.slide-label {
  display: block;
  display: -webkit-box;
  overflow: hidden;
  line-clamp: 2;
	-webkit-line-clamp: 2;
	-webkit-box-orient: vertical; 
}
</style>


