<template id="animal-select">
  <!-- Add custom input to set a custom validity to, that gets checked, before submit. Has no other purpose -->
  <input tabindex="-1" inputmode="none" @focus.stop="$event => cancelFocus($event)" type="text" class="invisible-input custom-validity" ref="customValidityInput" name="animalSelect->customValidity" />

  <ion-avatar
    v-if="showAvatar"
    :class="['avatar', 'gradient', (selectedAnimalIdRef == null) ? 'unselected' : undefined, (isAvatarClickable) ? 'clickable' : undefined]"
    slot="start"
    @click="(isAvatarClickable) ? selectAnimalInput.$el.click() : undefined"
    >
    <AuthenticatedMedia v-if="selectedAnimalAvatar != null" :mediaUrl="selectedAnimalAvatar" type="image"></AuthenticatedMedia>
    <div v-else-if="FALLBACK_AVATAR != null" class="avatar-icon">
      <font-awesome-icon v-if="FALLBACK_AVATAR_IS_FONT_AWESOME" :icon="FALLBACK_AVATAR" />
      <ion-icon v-else :icon="FALLBACK_AVATAR"></ion-icon>
    </div>
  </ion-avatar>

  <div v-if="viewOnly" class="view-animal-name">{{ selectedAnimalName || i18n.$t('report.view.no_horse') }}</div>
  <ion-select
    v-else
    :placeholder="i18n.$t('default_interaction.select')" 
    interface="action-sheet" 
    :value="selectedAnimalIdRef"
    @ion-change="selectAnimalId($event.target.value)"
    ref="selectAnimalInput" 
    :interface-options="{cssClass: 'select-animal'}"
    :cancel-text="i18n.$t('default_interaction.cancel')"
    @click="openCustomSelectInterface"
    mode="ios"
    :class="(selectedAnimalName == null) ? 'animal-no-name' : undefined"
    :disabled="disabled" >
    <ion-select-option v-for="(animal, animalId) in availableAnimals" :key="animalId"
      :value="animalId"
      :category="getAnimalLocation(animal).location.text"
      :order="getAnimalLocation(animal).spot.order"
      :additionalText="getAdditionalText(animal)"
      wrapAdditionalText
      :indicatorText="getAnimalLocation(animal).spot.text"
      :searchMetadata="getSearchMetadata(animal)"
      showAvatar
      :italic="animal.name == null"
      :avatarSrc="getAnimalAvatar(animal)">{{ animal.name || i18n.$t('report.create.horse_no_name') }}</ion-select-option>
    <ion-select-option class="new-button" unfiltered bold hideAdditionalText color="success-shade" value="new" :category="newAnimalCategory">{{ i18n.$t('report.create.new_horse') }}</ion-select-option>
  </ion-select>
  
  <div v-if="showAdditionalInfo" class="additional-info-text">
    <p class="inline-fa-icons" v-for="(additionalInfoText, additionalInfoIndex) in selectedAnimalAdditionalInfoArray" :key="additionalInfoIndex">{{ additionalInfoText }}</p>
  </div>
</template>

<script>
import { IonSelect, IonSelectOption, IonAvatar, IonIcon } from '@ionic/vue';
import { defineComponent, computed, ref, watch } from 'vue';
import { useStore } from 'vuex';

import { useI18n } from "@/utils/i18n";

import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import AuthenticatedMedia from '@/components/AuthenticatedMedia.vue';

import horseIcon from '@/assets/icons/horse.svg';

import { cancelFocus } from '@/utils/interaction';
import { hexToHue } from '@/utils/colors';
import { getAdditionalInfoArray, getAdditionalText, mapLocationsWithOrder, getLocation, getSearchMetadata } from '@/utils/animals';

import { openFilterPopupAsSelectInterface, default as filterPopupComponent } from '@/components/FilterPopup.vue';
import { useEditAnimalModal } from '@/components/composables/EditAnimalModal.js';

import _ from 'lodash';

export default defineComponent({
  name: 'AnimalSelect',
  components: { IonSelect, IonSelectOption, IonAvatar, IonIcon, AuthenticatedMedia, FontAwesomeIcon },
  props: {
    'selectedAnimalId': [String, Number],
    'availableAnimals': Object,
    'showAvatar': {
      type: Boolean,
      default: false
    },
    'showAdditionalInfo': {
      type: Boolean,
      default: false
    },
    'required': {
      type: Boolean,
      default: false
    },
    'disabled': {
      type: Boolean,
      default: false
    },
    'viewOnly': {
      type: Boolean,
      default: false
    }
  },
  emits: ['update:selectedAnimalId'],
  setup(props, { emit }) {
    const i18n = useI18n();

    const store = useStore();

    const selectAnimalInput = ref(null);

    const customValidityInput = ref(null);

    const editAnimalModal = useEditAnimalModal();

    const noValuePlaceholder = '—';

    const FALLBACK_AVATAR = horseIcon;
    const FALLBACK_AVATAR_IS_FONT_AWESOME = false;

    const newAnimalCategory = computed(() => {
      return i18n.$t('report.create.new_horse'); //TODO Maybe use different sentence. Like a question. Animal not found?
    });

    const unassignedPlaceholder = computed(() => {
      return i18n.$t('analysis.subject.unassigned');
    });

    const selectedAnimalIdProp = computed(() => props.selectedAnimalId);
    const selectedAnimalIdRef = ref(null);

    watch((selectedAnimalIdProp), (newSelectedAnimalId) => {
      //Only update and emit on change
      if (selectedAnimalIdRef.value != newSelectedAnimalId) {
        selectedAnimalIdRef.value = newSelectedAnimalId;
        //Always emit back when the preset changes and it is different from the currently selected ID
        emit('update:selectedAnimalId', (newSelectedAnimalId != null) ? newSelectedAnimalId : undefined);  //TODO Give ID as string or number? Always different from API on update! Maybe just don't emit unless it actually changes from preset?
      }
    }, { immediate: true });

    const isAvatarClickable = computed(() => {
      return (props.viewOnly == false && props.disabled == false);
    });

    const requiredProp = computed(() => props.required);

    watch([selectedAnimalIdRef, requiredProp, customValidityInput], ([newSelectedAnimalId, newRequired, currentCustomValidityInput]) => {
      //Wait for custom validity input to be ready!
      if (currentCustomValidityInput == null) return;
      
      //Default message signifies no error
      let validityMessage = '';
      //If required but not set, signify missing error
      if (newRequired === true && newSelectedAnimalId == null) validityMessage = i18n.$t('default_interaction.input_required') || 'Input required'; //Use translated input required or fallback string

      currentCustomValidityInput.setCustomValidity(validityMessage);
    }, { immediate: true });

    
    const availableLocationsObject = computed(() => {
      return mapLocationsWithOrder(store.getters['customization/getLocations']);
    });

    const availableLocationsOrder = computed(() => {
      //Copy existing orders over
      let orders = _.mapValues(availableLocationsObject.value, 'order');

      //Always move unassigned to the end!
      orders[unassignedPlaceholder.value] = -1;

      //Move the new animal option all the way to the end after the unassigned
      orders[newAnimalCategory.value] = -2;

      return orders;
    });

    //Save the promise of the select to get the data when selecting the animal. Can be used to preset a name from the search.
    const customSelectPromise = ref(null);

    const openCustomSelectInterface = function(event) {
      customSelectPromise.value = openFilterPopupAsSelectInterface(filterPopupComponent, event, event.target, {
        fallbackAvatarIcon: FALLBACK_AVATAR,
        fallbackAvatarIconIsFontAwesome: FALLBACK_AVATAR_IS_FONT_AWESOME,
        noValuePlaceholder,
        categoryOrder: availableLocationsOrder.value
      });
    }

    const setOrCreateAnimal = function(id, animalNamePreset = null) {
      if (id === 'new') {
        let previouslySelectedAnimal = selectedAnimalIdProp.value;
        
        editAnimalModal.open(null, animalNamePreset).then((animalId) => {
          if (animalId != null) {
            emit('update:selectedAnimalId', animalId);
          } else {
            //Use catch handler in case no animal was created
            throw 'canceled';
          }
        })
        .catch(() => {
          //Reset the select
          selectedAnimalIdRef.value = undefined; //Set temporarily to undefined to refresh the select input
          selectedAnimalIdRef.value = previouslySelectedAnimal;
          //No need to update, it didn't change
        });
      } else {
        emit('update:selectedAnimalId', (id != null) ? id : undefined); //Always return undefined for non selected animal
      }
      
    }

    const selectAnimalId = function(id) {
      //Only trigger logic if it actually changed
      if (id != selectedAnimalIdProp.value) {
        //If we don't wait for a popup to close, just set the animal
        if (customSelectPromise.value == null) setOrCreateAnimal(id);
        else {
          //If a promise exists, wait for it and check if a searchTerm was sent
          customSelectPromise.value.then((data) => {
            let animalNamePreset = null;
            if (data != null && data.data != null && data.data['searchTerm'] != null) animalNamePreset = data.data['searchTerm'].trim();
            setOrCreateAnimal(id, animalNamePreset);
          });
        }
      }
    }

    const selectedAnimalInstance = computed(() => {
      if (props.availableAnimals == null || selectedAnimalIdRef.value == null) return null; //Return empty instance, if no animal selected or none available
      return props.availableAnimals[selectedAnimalIdRef.value];
    });

    const getAnimalAvatar = function(animal) {
      if (animal != null && animal.image != null && animal.image.blobURL != null) {
        return animal.image.blobURL;
      }
      return null;
    }

    const selectedAnimalAvatar = computed(() => {
      if (selectedAnimalInstance.value == null) return null; //Return empty animal avatar, if no animal selected
      return getAnimalAvatar(selectedAnimalInstance.value);
    });

    const getAnimalName = function(animal) {
      if (animal != null && animal.name != null) {
        return animal.name;
      }
      return null;
    }

    const selectedAnimalName = computed(() => {
      if (selectedAnimalInstance.value == null) return null; //Return empty animal name, if no animal selected
      return getAnimalName(selectedAnimalInstance.value);
    });

    const selectedAnimalAdditionalInfoArray = computed(() => {
      if (selectedAnimalInstance.value == null) return [];
      return getAdditionalInfoArray(selectedAnimalInstance.value);
    });

    const getAnimalLocation = computed(() => {
      return function(animal) {
        return getLocation(animal, unassignedPlaceholder.value, availableLocationsObject.value);
      }
    })

    return { 
      i18n,
      FALLBACK_AVATAR,
      FALLBACK_AVATAR_IS_FONT_AWESOME,
      newAnimalCategory,
      isAvatarClickable,
      selectedAnimalIdRef,
      selectedAnimalAvatar,
      selectedAnimalName,
      selectedAnimalAdditionalInfoArray,
      selectAnimalInput,
      customValidityInput,
      cancelFocus,
      openCustomSelectInterface,
      selectAnimalId,
      getAnimalAvatar,
      getAdditionalText,
      getSearchMetadata,
      getAnimalLocation,
      hexToHue
    };
  }
});
</script>

<style scoped>
ion-select {
  align-self: flex-start;
  --padding-start: var(--custom-padding-start, 20px);
}

ion-select.animal-no-name {
  font-style: italic;
}

.invisible-input {
  /* Avoid reflow of other elements */
  position: absolute;
  /* Show any invalidity popups in the center of the element. Moved down slightly to better point at the missing input. */
  left: 50%;
  bottom: 25%;
  /* Hide any possibly visible parts of the input */
  background: var(--ion-background-color, white);
  border: none;
  opacity: 0;
  z-index: -10;
  /* Prevent user clicks on element, that would open the dialog twice */
  pointer-events: none;
  /* Make the element as small as possible, so it still can show the validity popup */
  width: 1px;
  height: 1px;
}

.avatar-icon {
  border-radius: var(--border-radius);
  background: var(--background, white);
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: var(--custom-avatar-icon-size, 1em);
}

.avatar-icon > * {
  color: var(--ion-color-quaternary);
  width: 100%;
  height: 100%;
}

.avatar.clickable {
  cursor: pointer;
}

.avatar {
  background: var(--ion-color-quaternary);
  padding: 2px;
  width: var(--custom-avatar-size, 40px);
  height: var(--custom-avatar-size, 40px);
  margin-right: 10px;
  align-self: center;
}

.avatar.unselected {
  opacity: 0.65;
  filter: grayscale(100%);
}

.avatar.gradient {
  background: var(--ion-color-quaternary);;
}

.view-animal-name {
  align-self: flex-start;
  margin: 8px 4px;
}

.additional-info-text {
  align-self: flex-start;
  padding-left: 15px;
  padding-bottom: 10px;
  flex-shrink: 1;
  max-width: 100%;
  overflow: hidden;
}

.additional-info-text > p {
  font-size: 0.85em;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}
</style>


